import Helpers from '../../lib/helpers';

class BasketButtonAnimator {
  constructor({ selector }) {
    const forms = document.querySelectorAll(selector);

    if (forms.length <= 0) { return; }

    Helpers.forEach(forms, (form) => {
      form.addEventListener('ajax:before', (event) => {
        const button = event.currentTarget.querySelector('button');
        const span = button.querySelector('span');

        span.childNodes[2].data = button.getAttribute('data-js-text-spinner');
        button.classList.remove('added');
        button.classList.add('loading');
        span.querySelector('svg use').setAttribute('xlink:href', '#svg--loader');
      });

      form.addEventListener('ajax:success', (event) => {
        const button = event.currentTarget.querySelector('button');
        const span = button.querySelector('span');

        span.childNodes[2].data = button.getAttribute('data-js-text-complete');
        button.classList.remove('loading');
        button.classList.add('added');
        button.disabled = true;
        span.querySelector('svg use').setAttribute('xlink:href', '#svg--tick');

        setTimeout(() => {
          button.disabled = false;
          button.classList.remove('added');
          span.querySelector('svg use').setAttribute('xlink:href', '#svg--basket');
          span.childNodes[2].data = button.getAttribute('data-js-text-initial');
        }, 1500);
      });
    });
  }
}

export default BasketButtonAnimator;
